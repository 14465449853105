.header {
    height: 8em;
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
  }
  
  @media (max-width: 600px) {
    .header {
      height: 6em;
    }
  }
  



.nav {
  background-color: #eee;
 
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav  { 
  gap: 0;
}

.nav ul {
  padding-left: 10px;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}


.logo{
  padding: 10px;  
  
}

.logo-mobile {
  padding: 10px;  
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
   
}

.header-nav {
  color: #777;
}
