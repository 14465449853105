*, *::after, *::before {
    box-sizing: border-box;
  }
  
  .spinner {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    overflow: hidden;
    position: fixed;
    margin-top: -80px;
    margin-left: -80px;
    animation: text-color 2s ease-in-out infinite alternate;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    
  }
  
  .spinner-sector {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid transparent;
     
    animation: rotate var(--duration) var(--timing) infinite;
    pointer-events: none;
  }
  
  .spinner-sector-red {
    border-top-color: white;
    --duration: 1.5s;
    --timing: ease-in-out;
  }
  
  .spinner-sector-blue {
    border-left-color: #4637ab;
    --duration: 2s;
    --timing: ease-in;
  }
  
  .spinner-sector-green {
    border-right-color: black;
    --duration: 2.5s;
    --timing: ease-out;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes text-color {
    0% {
      color: rgba(0, 0, 0, 1);
    }
  
    50% {
      color: rgba(0, 0, 0, .5);
    }
  
    100% {
      color: rgba(0, 0, 0, .1);
    }
  }