
body
{
  width: 100vw; 
  /*background: #999999; */
  color: #000000;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  overflow-x: hidden; /* prevent horizontal scrollbar from appearing */
}
	
a {
	color: #4637ab;
}
	
a:hover {
	color: #4638ab;
}

.white-link {
  color: inherit;
}

.white-link:hover {
	color: #4637ab;
}
	


.btn {
  font-family: Poppins,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  letter-spacing: 1px;
  line-height: 46px;
  padding: 0 30px;
  text-transform: uppercase;
  transition: all .3s ease;
}

.card{
 
  border-color: #000000;
}
/* .card-body {
  background-color: none;
}

.card-header{
  background-color: #4638ab;
}  */

.card button {
  border-radius: 25px; 
  border-color: #4637ab;
  background-color:#4637ab;
}

.card h2 {
  color: #000000; 
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card label {
  color: #000000;  
}

.gray-text {
  color: #212121; 
  margin: 10px 0;
}

.assetContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.assetList {  
  width: 100%;
  padding-left: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, auto); 
}
 

.soundControlers {
  color: #212121;  
  margin-bottom:30px;
} 
 

.imageItem { 
  background: #999999;
  border-radius: 10px;
  border: 2px solid #ddd;   
  min-width: 10vh;
  height: 20vh; 
  margin: 5px;
}

.imageItem img { 
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
 

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.videoItem { 
  width: 100%;
  height: 100%;
  position: relative;
  
}
 

.assetTitle{
  padding: 5px;
}



.qrContent{
  border-radius: 8px;
  border: 1px solid #ddd; 
  margin: 5px;
  padding: 10px;
}


.playAssetError{
  color: #e90606; 
  margin: 10px 0;
}

.modal-title {
  color: #212121; 
  margin: 10px 0;
}
 
.qrCreator{
  justify-content: space-between;
  display: flex;
  border: 1px solid #ddd; 
  border-radius: 8px;
  padding: 10px;
}

.creator-profile {
  display: flex;
}

.creator-initial { 
  display: flex;
  width: 50px;
  height: 50px;
  margin: auto;
  border-radius: 10px;
  border: 1px solid #212121;
  background-color: #4638ab;
  color: #fff;
  align-items:center;
  justify-content:center;
  padding-top: 8px;

}

.creator-avatar{
   
  border: 1px solid #ddd; 
  width: 50px;
  height: 50px;
  margin: 5px;
  object-fit: cover;
  border-radius: 8px;
}

.creator-name{ 
  text-align: left;  
  padding: 5px;
  font-size: 12px;
}

.small-text {
  text-transform: lowercase;
}

.creator-name p{ 
  margin: auto;
}


.createdAt {
  font-size: 12px;
  padding-top: inherit;
  text-align: right;
  display: flex;
  align-items: center;
}

.showPasswordText{
  display: flex;
   
}

.eyeIcon{
  padding-left: 5px;
  margin-top: 5px;
}

.pagination {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.totalUser {
  padding-top: 20px;
  margin-left: 0;
  float: left;
}


.searchBox {
  vertical-align: baseline;
  float: right;
}

.searchText{
  vertical-align: baseline !important;
  
}


.vertical-center {
  
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.social-button {
  width: 200px;
  padding-bottom: 10px;
}

.social-button div{
  width: 200px!important;
}
 